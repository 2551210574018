<template>
	<div class="main">
		<!-- <div class="location">
			<div class="cur-location">
				<span style="line-height: 1">您所在的位置：</span>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>角色管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->
		<common-header :formModel="formInline" @query="query" class="common_header">
			>
			<template v-slot:form>
				<el-form-item :label="$t('role.roleName')" prop="param.roleName">
					<el-input v-model="formInline.param.roleName"></el-input>
				</el-form-item>
				<el-form-item :label="$t('role.status')" prop="param.status">
					<el-select v-model="formInline.param.status" placeholder="请选择" style="width: 100%">
						<el-option
							v-for="item in roleStatus"
							:key="item.code"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
			</template>
			<template v-slot:tools>
				<tools-btn left-icon="add" @click.native="openDialogVisible()">{{ $t('common.add') }}</tools-btn>
			</template>
		</common-header>
		<div class="table tableHeight">
			<el-table border stripe size="mini" height="100%" :data="tableData">
				<el-table-column
					show-overflow-tooltip
					prop="roleName"
					:label="$t('role.roleName')"
					width="200px"
				></el-table-column>
				<el-table-column show-overflow-tooltip prop="note" :label="$t('role.note')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="status" :label="$t('role.status')" width="140">
					<template v-slot="{ row }">
						{{ row.status | mapLabel('roleStatus') }}
					</template>
				</el-table-column>
				<el-table-column :label="$t('common.operation')" width="140">
					<template v-slot="{ row }">
						<el-button size="mini" type="text" @click="openDialogVisible(row)">
							{{ $t('common.edit') }}
						</el-button>
						<el-dropdown trigger="click" style="margin-left: 10px" @command="handleCommand">
							<el-button size="mini" type="text">
								{{ $t('common.moreOperate') }}
								<i class="el-icon-arrow-down el-icon--right"></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="beforeHandleCommand(row, 'resource')">
									{{ $t('role.configResource') }}
								</el-dropdown-item>
								<el-dropdown-item :command="beforeHandleCommand(row, 'del')">
									{{ $t('common.del') }}
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<gl-page
			@size-change="sizeChange"
			@current-change="query"
			:current-page="formInline.pageNum"
			:page-size="formInline.pageSize"
			:total="total"
		></gl-page>

		<el-dialog
			:title="dialogTitle"
			:visible.sync="dialogVisible"
			center
			width="400px"
			:before-close="closeDialogVisible"
			id="dlg_edit"
		>
			<el-form ref="addEditForm" :model="addEditForm" :rules="addEditRules" label-width="90px" inline>
				<el-form-item :label="$t('role.roleName')" prop="roleName">
					<el-input v-model="addEditForm.roleName"></el-input>
				</el-form-item>
				<el-form-item :label="$t('role.status')" prop="status">
					<el-select v-model="addEditForm.status" placeholder="请选择" style="width: 100%">
						<el-option
							v-for="item in roleStatus"
							:key="item.code"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('role.note')">
					<el-input type="textarea" v-model="addEditForm.note"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" style="padding-bottom: 5px">
				<el-button @click="closeDialogVisible">{{ $t('common.cancel') }}</el-button>
				<el-button type="primary" @click="save()">{{ $t('common.confirm') }}</el-button>
			</span>
		</el-dialog>

		<el-dialog
			:title="dialogResourceTitle"
			:visible.sync="dialogResourceVisible"
			center
			width="500px"
			id="dlg_tree"
			:before-close="closeDialogResourceVisible"
		>
			<el-tabs v-model="resouresType" tab-position="left" type="border-card" class="tabs_tree commonTab">
				<el-tab-pane
					v-for="item in permissionData"
					:label="item.resouresTypeName"
					:key="item.resouresTypeCode"
					:name="item.resouresTypeCode"
				>
					<el-tree
						:props="resourceProps"
						:data="item.resouresTrees"
						:ref="item.resouresTypeCode"
						node-key="id"
						show-checkbox
					></el-tree>
				</el-tab-pane>
			</el-tabs>

			<span slot="footer" style="padding-bottom: 5px">
				<el-button @click="closeDialogResourceVisible">{{ $t('common.cancel') }}</el-button>
				<el-button type="primary" @click="saveRoleResoures()">{{ $t('common.confirm') }}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'role',
	components: {},
	data() {
		return {
			formInline: {
				pageNum: 1,
				pageSize: 10,
				param: {
					roleName: '',
					status: ''
				}
			},
			addEditForm: {
				note: '',
				roleId: '',
				roleName: '',
				status: ''
			},
			resetArr: {
				note: '',
				roleId: '',
				roleName: '',
				status: ''
			},
			total: 0,
			tableData: [],
			dialogVisible: false,
			dialogTitle: '',
			roleStatus: [],
			addEditRules: {
				roleName: [
					{ required: true, message: this.$t('common.PsEnter') + this.$t('role.roleName'), trigger: 'blur' }
				],
				status: [
					{ required: true, message: this.$t('common.PsSelect') + this.$t('role.status'), trigger: 'change' }
				]
			},
			resouresType: '',
			dialogResourceVisible: false,
			dialogResourceTitle: this.$t('role.configResource'),
			permissionData: [],
			resourceProps: {
				children: 'children',
				label: 'resourceName'
			},
			configResourceForm: {
				roleId: '',
				resIds: []
			}
		}
	},
	mounted() {
		var enums = this.$store.state.enum
		this.roleStatus = enums[`roleStatus`]
		this.query()
	},
	methods: {
		beforeHandleCommand(row, command) {
			return {
				row: row,
				command: command
			}
		},
		handleCommand(command) {
			switch (
				command.command //角标index获取为command.index
			) {
				case 'resource':
					this.configResource(command.row)
					break
				case 'del':
					this.del(command.row)
					break
			}
		},
		sizeChange(i) {
			this.formInline.pageSize = i
			this.query()
		},
		openDialogVisible(row) {
			this.addEditForm = JSON.parse(JSON.stringify(this.resetArr))
			if (row) {
				Object.assign(this.addEditForm, row)
				this.dialogTitle = this.$t('common.edit') + this.$t('role.title')
			} else {
				this.dialogTitle = this.$t('common.add') + this.$t('role.title')
			}
			this.dialogVisible = true
		},
		closeDialogVisible() {
			this.dialogVisible = false
		},
		query(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.formInline.pageNum = i
			this.ajax.post('/admin/sys/role/pageInfo', this.formInline).then(res => {
				this.tableData = res.list
				this.total = res.total
			})
		},
		save() {
			this.$refs.addEditForm.validate(valid => {
				if (valid) {
					this.ajax.post('/admin/sys/role/saveSysRole', this.addEditForm).then(() => {
						this.$message({
							message: this.$t('common.success'),
							type: 'success'
						})
						this.closeDialogVisible()
						this.query()
					})
				}
			})
		},
		configResource(row) {
			this.ajax.get('/admin/sys/role/resouresList').then(res => {
				this.permissionData = res
				this.resouresType = this.permissionData[0].resouresTypeCode
				this.configResourceForm.roleId = row.roleId
				this.dialogResourceVisible = true
				this.getResourceByRoleId(row.roleId)
			})
		},
		closeDialogResourceVisible() {
			this.dialogResourceVisible = false
		},
		getResourceByRoleId(roleId) {
			let that = this
			this.ajax.get('/admin/sys/role/findSysResByRole?roleId=' + roleId).then(res => {
				if (res) {
					for (let permission of this.permissionData) {
						this.$nextTick(() => {
							let resouresType = permission.resouresTypeCode
							res.map(e => {
								let node = that.$refs[resouresType][0].getNode(e)
								if (node && node.isLeaf) {
									that.$refs[resouresType][0].setChecked(node, true)
								}
							})
						})
					}
				}
			})
		},
		saveRoleResoures() {
			this.configResourceForm.resIds = []
			let tmpIds = []
			for (let permission of this.permissionData) {
				let resouresIds = this.$refs[permission.resouresTypeCode][0].getCheckedNodes(true, true).map(e => {
					return e.id
				})
				console.log(resouresIds)
				tmpIds = tmpIds.concat(resouresIds)
			}
			this.configResourceForm.resIds = tmpIds
			this.ajax.post('/admin/sys/role/saveSysRoleUseRes', this.configResourceForm).then(() => {
				this.$message({
					message: this.$t('common.success'),
					type: 'success'
				})
				this.dialogResourceVisible = false
			})
		},
		del(row) {
			this.$confirm(
				this.$t('common.confirm') + this.$t('common.del') + this.$t('role.title') + '?',
				this.$t('common.Tips'),
				{
					confirmButtonText: this.$t('common.confirm'),
					cancelButtonText: this.$t('common.cancel'),
					closeOnClickModal: false,
					type: 'warning'
				}
			).then(() => {
				let form = {
					id: row.roleId
				}
				this.ajax.post('/admin/sys/role/deleteSysRole', form).then(() => {
					this.$message({
						message: this.$t('common.success'),
						type: 'success'
					})
					this.query()
				})
			})
		}
	}
}
</script>
<style lang="scss" scoped>
// #dlg_edit ::v-deep .el-dialog {
// 	height: 350px;
// }
#dlg_tree ::v-deep .el-dialog {
	height: 500px;
}
::v-deep .el-dialog__body .el-form--inline .el-textarea__inner {
	width: 220px;
}
.tabs_tree {
	height: 400px;
	::v-deep .el-tabs__content {
		height: 100%;
		overflow: auto;
	}
}
</style>
